import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Hash } from '@cybus/helps/dist/types/Hash';
import { ReactNode } from 'react';

export interface IDialog {
  id: string;
  className?: string;
  title?: ReactNode | string;
  message: string;
  buttons?: { label: string; className?: string; action: string; payload?: any }[];
  closeOnOutsideClick?: boolean;
  xBtn?: boolean;
  okBtn?: string;
}
export interface IDialogAction {
  id: string;
  action: string;
  payload?: any;
}
interface DialogState {
  value: IDialog[];
  pendingAction?: IDialogAction;
}

const initialState: DialogState = {
  value: [],
  pendingAction: undefined,
};

const cache: Hash<IDialog> = {};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    addDialog: (state, action: PayloadAction<IDialog>) => {
      const dialog = Object.assign({}, action.payload);
      cache[dialog.id] = dialog;
      state.value = Object.values(cache);
    },
    setDialogAction: (state, action: PayloadAction<IDialogAction>) => {
      state.pendingAction = action.payload;
    },
    removeDialog: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete cache[id];
      state.value = Object.values(cache);
      state.pendingAction = undefined;
    },
  },
});

export const { addDialog, removeDialog, setDialogAction } = dialogSlice.actions;

export const getDialogs = (state: any) => state.dialogs.value as IDialog[];

export const getDialogPendingAction = (state: any) => state.dialogs.pendingAction as IDialogAction;
export const dialogReducer = dialogSlice.reducer;
