import { useDispatch, useSelector } from 'react-redux';
import { Row, Spinner } from '../reactlib';
import { getLoading } from '../reactlib/features/loading';
import { setDrawerOpen } from '../reactlib/features/drawer';
import { getUser } from '../reactlib/features/users';
import MultiRoute from '@cybus/helps/dist/helpers/MultiRoute';
import { IconLogo } from './IconLogo';
import { SiteConfig } from '../SiteConfig';
import { TReferrer } from '../reactlib/types/TSiteConfig';

export default function MainNav() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { small } = useSelector(getLoading);
  const referrers:TReferrer[] = Object.values(SiteConfig.referrers ?? [{id: 'default', name: 'default', color: 'black', logo: {src: '/logo-white.svg', scale: 0.25, width: 330, height: 170 } }]);
  return (
    <div className="navbar bg-neutral border-b border-gray-400 drop-shadow-lg">
      <div className="flex-none">
        <button className="btn btn-square btn-ghost" onClick={() => dispatch(setDrawerOpen(true))}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              stroke="#fff"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex-1 justify-center">
        hello
        <a className="btn btn-ghost normal-case">
          {referrers.map(r => <IconLogo key={r.id} referrer={r} scale={0.7/(referrers.length ?? 1)} />)}
        </a>
      </div>
      {user?.id ? (
        <Row className="relative items-center justify-center">
          <div className="absolute -top-3 -right-3 w-16 h-16 z-0 pointer-events-none">
            <Spinner loaded={small} bg="bg-neutral" color="white" />
          </div>
          <button className="btn btn-circle btn-neurtal" onClick={() => MultiRoute.go(0, `/users/${user.id}`)}>
            <div className="avatar placeholder">
              <Row className="w-12 mask mask-hexagon">
                <span className="text-xl">
                  {user?.firstName?.[0]}
                  {user?.lastName?.[0]}
                </span>
                {/* <img src="/images/stock/photo-1534528741775-53994a69daeb.jpg" /> */}
              </Row>
            </div>
          </button>
        </Row>
      ) : (
        ''
      )}
    </div>
  );
}
