import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// import { RootState } from '../../store';
import sortBy from '@cybus/helps/dist/helpers/sortBy';
import { Hash } from '@cybus/helps/dist/types/Hash';
import extend from '@cybus/helps/dist/helpers/extend';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';
import { ICustomer } from '../customer';

export const getDefaultLead = (): ICustomer => {
  return {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phones: [{ phone: '', type: 'mobile', primary: true }],
    addresses: [{ street: '', city: '', state: '', zip: '' }],
    createdAt: 0,
    createdBy: '',
  };
};

const sort = sortBy('createdAt', false);
const feature = createReduxListFeature('lead', getDefaultLead, sort);
feature.reducers.addLeadPhone = (state: any, action: PayloadAction<string>) => {
  const id = action.payload as string;
  const lead = extend({}, state.hash[id]);
  lead.phones.push({ phone: '', type: 'mobile', primary: false });
  state.value = lead;
  state.hash[id] = lead;
  feature.update(state);
};
feature.reducers.addLeadAddress = (state: any, action: PayloadAction<string>) => {
  const id = action.payload as string;
  const lead = extend({}, state.hash[id]);
  lead.addresses.push({ street: '', city: '', state: '', zip: '' });
  state.value = lead;
  state.hash[id] = lead;
  feature.update(state);
};
export const leadSlice = createSlice(feature);

export const {
  setValue: setLead,
  setList: setLeads,
  update: updateLead,
  remove: removeLead,
  addLeadPhone,
  addLeadAddress,
} = leadSlice.actions;

export const getLead = (state: any) => state.lead.value as ICustomer;
export const getLeads = (state: any) => state.lead.list as ICustomer[];
export const getLeadById = (state: any) => state.lead.hash as Hash<ICustomer>;

export const leadReducer = leadSlice.reducer;
