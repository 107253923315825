import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IToast {
  id?: string; // assigned when added to the queue
  message: string;
  type: 'alert-info' | 'alert-success' | 'alert-warning' | 'alert-error';
  life?: number;
  delay?: number;
  start?: number;
  expired?: boolean;
  transition?: number;
}
interface ToastState {
  value: IToast[];
}

const initialState: ToastState = {
  value: [],
};

export const toastSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<IToast>) => {
      const toast = Object.assign({}, action.payload);
      toast.start = Date.now();
      toast.delay = toast.delay || 3000;
      toast.transition = toast.transition || 250;
      state.value.push(toast);
    },
    expireToasts: (state) => {
      const now = Date.now();
      const result = [];
      let change = false;
      for (let i = 0; i < state.value.length; i += 1) {
        const toast = state.value[i];
        const start = toast.start as number;
        const delay = toast.delay as number;
        const transition = toast.transition as number;
        const diff = start + delay;
        if (now > diff) {
          change = true;
          toast.expired = true;
        }
        if (now < diff + transition) {
          result.push(toast); // once over the delay and tansition. we don't add it back.
        }
      }
      state.value = result;
    },
  },
});

export const { addToast, expireToasts } = toastSlice.actions;

export const getToasts = (state: any) => state.toasts.value as IToast[];

export const toastsReducer = toastSlice.reducer;
