import { TSiteConfig } from "./reactlib/types/TSiteConfig";


export const SiteConfig:TSiteConfig = {
  systemUserId: '',
  type: 'Pickleball Court Construction',
  company: 'Fortis Courts',
  address: {street: '2188 E 3670 S', city: 'Saint George', state:'UT', zip: '84790'},
  phone: '8016871060',
  email: 'brig@fortiscourts.com',
  website: 'fortiscourts.com',
  onesignal: {
    appId: '',
    domain: 'admin.fortiscourts.com'
  },
}

// if we are restricting by referrer then we need to set this.
// customerFilter.wp = ['referrer', '==', 'null'];
