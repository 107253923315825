import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';
import ErrorBoundary from './reactlib/components/ErrorBoundary/ErrorBoundary';
import GLog from '@cybus/helps/dist/helpers/GLog';
import ErrorFallback from './reactlib/components/ErrorBoundary/ErrorFallback';

const rootEl = document.getElementById('root') as HTMLElement;
rootEl.classList.add('min-h-screen', 'h-1');
const root = ReactDOM.createRoot(rootEl);
const gl = GLog.init('root', 'red', 1);
ErrorBoundary.setErrorServiceFn(gl.warn);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
