import { createSlice } from '@reduxjs/toolkit';
import { CRUD, IPermission } from './IPerm';
import createReduxListFeature from '@cybus/helps/dist/helpers/createReduxListFeature';
import { Hash } from '@cybus/helps/dist/types/Hash';
import extend from '@cybus/helps/dist/helpers/extend';

const cache = {
  def: {
    id: '',
    customers: CRUD.NONE,
    dev: CRUD.NONE,
    login: CRUD.NONE,
    permissions: CRUD.NONE,
    users: CRUD.NONE,
  } as IPermission,
};
export function setDefaultPermission(v: Partial<IPermission>): void {
  cache.def = extend(cache.def, v) as IPermission;
}

export const getDefaultPermission = (): IPermission => {
  return extend({}, cache.def);
};

export const permissionSlice = createSlice(createReduxListFeature<IPermission>('permissions', getDefaultPermission));

export const { setValue: setPermission, setList: setPermissions, update: updatePermission } = permissionSlice.actions;

export const getPermission = (state: any) => state.permission.value as IPermission;
export const getPermissions = (state: any) => state.permission.hash as Hash<IPermission>;

export const permissionReducer = permissionSlice.reducer;
